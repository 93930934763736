import { makeAutoObservable } from "mobx";
import { token } from "api/user";
import type {
  UserInterface,
  UserStatisticsInterface,
  UserFeesAndLimitsInterface,
  RequestedForm,
  UserCompanyData,
} from "helpers/types";

const initialUserState: UserInterface = {
  id: "",
  email: "",
  token: "",
  referralCode: "",
  emailVerified: false,
  level: "BASIC",
  kycStatus: "NEW",
  is2FAEnabled: false,
  type: "REGULAR",
};

class Store {
  user: UserInterface = initialUserState;
  isAuthenticated: boolean = false;
  isLoggedAsAdmin: boolean = false;
  statistics: UserStatisticsInterface | null = null;
  feesAndLimits: UserFeesAndLimitsInterface | null = null;
  requestedForms: RequestedForm[] | null = null;
  userCompanyInfo: UserCompanyData | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAuthenticated = (userData: UserInterface) => {
    this.isAuthenticated = true;
    this.user = userData;
  };

  logOut = () => {
    token.unset();
    this.isAuthenticated = false;
    this.user = initialUserState;
    this.isLoggedAsAdmin = false;
    localStorage.removeItem("isLoggedAsAdmin");
  };

  setStatistics = (statistics: UserStatisticsInterface | null) => {
    this.statistics = statistics;
  };

  setFeesAndLimits = (feesAndLimits: UserFeesAndLimitsInterface) => {
    this.feesAndLimits = feesAndLimits;
  };

  setRequestedForms = (requestedForms: RequestedForm[] | null) => {
    this.requestedForms = requestedForms;
  };

  setIsLoggedAsAdmin = () => {
    this.isLoggedAsAdmin = true;
  };

  toggle2FA = () => {
    this.user = { ...this.user, is2FAEnabled: !this.user.is2FAEnabled };
  };

  setUserCompanyInfo = (userCompanyInfo: UserCompanyData) => {
    this.userCompanyInfo = userCompanyInfo;
  };
}

const userStore = new Store();
export default userStore;
