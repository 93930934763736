import { ChangeEventHandler, FC, useMemo, useState } from "react";
import styled from "styled-components";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { TextInputProps } from "./types";
import { AppText } from "components";
import { SelectItemProps } from "components/Inputs/types";
import { colors, mainFont } from "helpers/consts";
import { Select } from "components/Inputs";
import { pnoneInputValidator } from "helpers/regex";
import countries from "helpers/countries";

// import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down-mini.svg";

interface PhoneInputProps extends TextInputProps {
  phonePref: string | null;
  setPref: (val: string) => void;
  error?: boolean;
  helperText?: string | false | null;
  onBlur?: void | ((e: any) => void);
}

const PhoneInput: FC<PhoneInputProps> = ({
  value,
  setValue,
  disabled,
  onBlur,
  phonePref,
  setPref,
  error,
  helperText,
  name,
  placeholder,
}) => {
  const [search, setSearch] = useState<string>("");
  const handleChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    if (!setValue) {
      return null;
    }
    const { value } = ev.target;
    const canSetNewValue = pnoneInputValidator.test(value) || !value.length;

    if (canSetNewValue && setValue) {
      setValue(value);
    } else setValue(value.replace(/\D/g, ""));
  };

  const countriesPrefOptions = useMemo<Array<SelectItemProps>>(() => {
    return countries.map(({ flag, dial_code, name }) => ({
      value: name,
      label: (
        <AppText>
          {flag} {name} {dial_code}
        </AppText>
      ),
      key: dial_code,
    }));
  }, []);

  return (
    <>
      <StyledTextField
        value={value}
        onChange={handleChange}
        //@ts-ignore
        onBlur={onBlur}
        disabled={disabled}
        error={error}
        name={name}
        startAdornment={
          <InputAdornment position="start">
            <Select
              value={phonePref || ""}
              variants={countriesPrefOptions}
              setValue={setPref}
              disabled={disabled}
              withSearch
              searchValue={search}
              setSearch={setSearch}
            />
          </InputAdornment>
        }
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        fullWidth
        placeholder={placeholder || "0"}
      />
      {helperText && (
        <AppText fontSize={12} color={colors.error_500}>
          {helperText}
        </AppText>
      )}
    </>
  );
};

const StyledTextField = styled(OutlinedInput)`
  padding-left: 0px;

  & > .MuiInputAdornment-root .MuiFormControl-root {
    min-width: 10rem;
    min-height: 5rem;
    height: 100%;
    & > .MuiInputBase-root {
      min-height: 5rem;
      height: 100%;
    }
  }
  & .MuiSelect-select {
    display: flex;
    align-items: center;

    & > p.MuiTypography-root {
      font-family: "Twemoji Country Flags", ${mainFont};
      margin-top: 0.2rem;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${colors.primary_500};
  }
  &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  &:focus-within .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: ${colors.primary_500};
  }
  &.Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: ${colors.error_500};
  }

  .MuiInputBase-input {
    padding: 1.4rem 0 1.4rem 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: ${colors.gray_700};
    border-radius: 0.8rem;
    background-color: ${colors.white};
    transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:focus {
      color: ${colors.primary_500};
    }
    &:disabled {
      color: ${colors.gray_500};
      background-color: ${colors.gray_50};
    }
    &:disabled + fieldset {
      border: 1px solid ${colors.gray_300};
    }
  }
  .MuiInputBase-root {
    border-radius: 0.8rem;
    ${({ disabled }) => disabled && `background-color: ${colors.gray_50};`}
  }
  fieldset {
    border: 1px solid ${colors.gray_300};
    border-radius: 0.8rem;
  }
  .MuiInputAdornment-root {
    height: 100%;
    margin-left: 0;
    max-height: 5rem;
    min-height: 5rem;
    margin-right: 0;
  }
`;

export default PhoneInput;
