import axios from "axios";
import { WalletStore, GlobalStore } from "stores";
import type {
  HistoryReqInterface,
  HistoryResponseInterface,
  WithdrawReqInterface,
  CreateNewAddressRes,
} from "./apiTypes";

export const getWalletsList = async () =>
  axios
    .get("/wallet/list")
    .then(({ data }) => WalletStore.setWalletsList(data))
    .catch((err) => {
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    });

export const createWallet = async (code: string) =>
  axios
    .get(`/wallet/currency/${code}`)
    .then(({ data }) => {
      WalletStore.addWallet(data);
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    });

// OLD wallet address create
// export const createWalletAddress = async (code: string, currency: string) =>
//   axios
//     .get(`/wallet/address/${code}`)
//     .then(({ data }) => {
//       WalletStore.addWalletAddress(data, currency);
//     })
//     .catch((err) => {
//       if (err?.response?.data?.message) {
//         GlobalStore.setError(err.response.data.message);
//       }
//     });

export const createWalletAddress = async (
  networkCode: string,
  currencyCode: string
) =>
  axios.get<CreateNewAddressRes>(
    `/scrooge/deposit-address/currency/${currencyCode}/network/${networkCode}`
  );

export const getHistory = async (reqData: HistoryReqInterface) =>
  axios.get<HistoryResponseInterface>(`/wallet/operation/list`, {
    params: {
      ...reqData,
    },
  });

export const withdraw = async (reqData: WithdrawReqInterface) =>
  axios.post(`/scrooge/withdraw`, reqData);
