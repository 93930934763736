import { useEffect, useState } from "react";
const requiredLength = 8;

const usePasswordHints = (newPassword: string) => {
  const [hasValidLength, setHasValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  useEffect(() => {
    setHasValidLength(newPassword.length >= requiredLength ? true : false);
    setHasUpperCase(newPassword.toLowerCase() !== newPassword);
    setHasLowerCase(newPassword.toUpperCase() !== newPassword);
    setHasNumber(/\d/.test(newPassword));
    setHasSpecialChar(
      /[ `!@#$%^&*()_/+\-=\]{};':"\\|,.<>?~]/.test(newPassword)
    );
  }, [newPassword]);

  return {
    hasValidLength,
    hasNumber,
    hasUpperCase,
    hasLowerCase,
    hasSpecialChar,
  };
};

export default usePasswordHints;
