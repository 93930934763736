import { FC, ReactNode } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { TextInput } from "components/Inputs";
import { AppText } from "components";
import { CustomSelectProps, SelectControlProps } from "./types";
import { colors, mainFont } from "helpers/consts";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down-mini.svg";

const BasicSelect: FC<CustomSelectProps> = ({
  variants,
  value,
  setValue,
  onChange,
  onBlur,
  label,
  placeholder,
  maxWidth,
  acceptEmpty,
  name,
  error,
  helperText,
  isStyled,
  withSearch,
  searchValue,
  setSearch,
  disabled,
}) => {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent, child: ReactNode) => {
    if (!acceptEmpty && event.target.value === "") {
      return;
    }
    !!onChange && onChange(event, child);
    !!setValue && setValue(event.target.value as string);
    !!setSearch && setSearch("");
    !!onBlur && onBlur(event);
  };

  return (
    <StyledFormControl
      fullWidth
      $maxWidth={maxWidth}
      $isStyled={isStyled}
      $withSearch={withSearch}
    >
      {label && <InputLabel id={`select-label-${value}`}>{label}</InputLabel>}

      <Select
        labelId={label ? `select-label-${label}` : ""}
        value={value}
        label={label ? label : null}
        name={name}
        onBlur={(e) => {
          !!onBlur && onBlur(e);
          !!setSearch && setSearch("");
        }}
        onChange={handleChange}
        sx={{
          "& .MuiSelect-select .notranslate::after": placeholder
            ? {
                content: `"${placeholder}"`,
                opacity: 0.42,
              }
            : {},
        }}
        IconComponent={ArrowDownIcon}
        error={error}
        disabled={disabled}
      >
        {withSearch && (
          <SearchContent>
            <TextInput
              value={searchValue || ""}
              onChange={({ target: { value } }) =>
                !!setSearch ? setSearch(value) : null
              }
              inputRef={(ref) =>
                ref &&
                setTimeout(() => {
                  ref.focus();
                }, 10)
              }
              sx={{
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: "0.8rem 1rem",
                },
              }}
              placeholder={t("SEARCH")}
              autoComplete="off"
            />
          </SearchContent>
        )}

        {acceptEmpty && value && (
          <StyledMenuItem value="">{t("ALL")}</StyledMenuItem>
        )}
        {withSearch
          ? variants.map(({ value, label, key }) => {
              if (
                key!.toLowerCase().includes(searchValue!.toLowerCase()) ||
                String(value).indexOf(searchValue!) > -1 ||
                String(key).indexOf(searchValue!) > -1
              ) {
                return (
                  <StyledMenuItem key={key || value} value={value}>
                    {typeof label === "string" ? t(label) : label}
                  </StyledMenuItem>
                );
              }
              return (
                <StyledMenuItem
                  key={key || value}
                  value={value}
                  style={{ display: "none" }}
                >
                  {typeof label === "string" ? t(label) : label}
                </StyledMenuItem>
              );
            })
          : variants.map(({ value, label, key }) => (
              <StyledMenuItem key={key || value} value={value}>
                {typeof label === "string" ? t(label) : label}
              </StyledMenuItem>
            ))}
      </Select>
      {helperText && (
        <AppText
          fontSize={11}
          color={colors.error_500}
          style={{ margin: "3px 14px 0px" }}
        >
          {helperText}
        </AppText>
      )}
    </StyledFormControl>
  );
};

const StyledMenuItem = styled(MenuItem)`
  & > .MuiTypography-root {
    font-family: "Twemoji Country Flags", ${mainFont};
  }
`;

const StyledFormControl = styled(FormControl)<SelectControlProps>`
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}

  min-width: 20rem;

  fieldset > legend > span {
    font-size: 1.2rem;
  }

  .MuiInputLabel-root,
  .MuiInputBase-root {
    font-size: 1.6rem;
  }
  .MuiInputBase-root {
    border-radius: 0.8rem;
    font-weight: 500;

    background: ${({ $isStyled }) =>
      $isStyled
        ? "linear-gradient(90deg, rgba(255, 255, 255, 1) 70%, rgba(247, 144, 9, 0.1) 100%)"
        : colors.white};
    ${({ disabled }) => disabled && `background: ${colors.gray_50};`}

    ${({ $isStyled }) =>
      $isStyled &&
      `> svg path {
      stroke: ${colors.primary_500};
    }`}

    &:not(::disabled):hover,
    &:not(::disabled):focus {
      fieldset {
        border: 1px solid ${colors.primary_500};
      }
    }
    &:not(::disabled):focus-within {
      fieldset {
        border: 2px solid ${colors.primary_500};
      }
    }
    &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
    &:focus-within .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
      border-color: ${colors.primary_500};
    }
  }
  .MuiSelect-select {
    padding: ${({ $isStyled }) =>
      $isStyled ? "1.45rem 1.4rem" : "1rem 1.4rem"};
    border-radius: 0.8rem;

    > span {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${colors.gray_500};
    }
    > .notranslate::after {
      opacity: 1;
    }
  }
  &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &:focus-within .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${colors.primary_500};
  }
  .Mui-disabled {
    background-color: ${colors.gray_100};
    opacity: 0.8;

    & .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.gray_300};
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.gray_300};
    }
  }
  & .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: ${colors.error_500};
  }
  .MuiOutlinedInput-notchedOutline {
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  & .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.error_500};
  }
  fieldset {
    border: 1px solid
      ${({ $isStyled }) => ($isStyled ? colors.gray_100 : colors.gray_300)};
    border-radius: 0.8rem;
    transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

const SearchContent = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: ${colors.white};
  padding: 0.4rem;
`;

export default BasicSelect;
